import React from 'react'

import "./navbar.css"
import logo from "../../asset/logo2.png"

const Navbar = ({ isSticky }) => {

  const navigateToHome = () =>{
    window.location.href = "/"
  }
  const navigateToPortfolio = () =>{
    window.location.href = "/portfolio"
  }
  const navigateToAddEvent = () =>{
    window.location.href = "/addEvent"
  }

  return (
    <div className={isSticky ? 'navbar sticky' : 'navbar'}>
        <div className="navbar-container">
            <div className="navbar-logo" onClick={navigateToHome}>
              <img src={logo} alt="" />
            </div>
            <div className="navbar-menu">
                <p onClick={navigateToHome}>Home</p>
                <p onClick={navigateToPortfolio}>Portfolio</p>
                <p >Services</p>
                <p>About</p>
                <p>Contact</p>
              <div className="navbar-contact" onClick={navigateToAddEvent}>
                <p style={{color:"white"}}>Add Event</p>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar