import React, {useEffect} from 'react'

import hv from "../../asset/hv1.jpg"
import play from "../../asset/play.png"

import "./homevideo.css"

import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeVideo = () => {

    useEffect(() => {
        AOS.init();
      }, [])

  return (
    <div>
        <div className="homevideo-container">
            <div className="homevideo-image">
                <img src={hv} alt="" />
            </div>
            <div className="homevideo-opacity"></div>
            <div className="homevideo-title">
                <h3 data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1500">ELEGANT SOLUTIONS</h3>
                <h1 data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1700">See For Yourself</h1>
                <div className="homevideo-button" data-aos="zoom-in"  data-aos-easing="linear" data-aos-duration="1900">
                    <img src={play} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeVideo