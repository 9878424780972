import React from 'react'
import GalleryCard from '../../components/gallerycard/GalleryCard'
import h3 from "../../asset/h3.jpg"
import "./portfolio.css"

import arch from "../../asset/wedding.png"

const Portfolio = () => {
  return (
    <div className='portfolio'>
       <div className='header'>
      <div className="header-container">
          <div className='header-images'>
            <img src={h3} alt="" />
          </div>
      </div>
      <div className="header-opacity"></div>
      <div className="header-text portfolio-text">
        <h3>OUR PORTFOLIO</h3>
        <h1>Visual Splendor</h1>

      </div>
    </div>
      <div className="portfolio-title">
      <div className="homegallery-title-container">
                <div className="homegallery-title">
                    <h1>Portfolio</h1>
                </div>
                <div className="homegallery-sub-title">
                    <h3>OUR PORTFOLIO</h3>
                    <h1>Lasting Impressions</h1>
                <div className="homegallery-footer">
                <div className="homegallery-footer-horizontal"></div>
                <div className="homegallery-footer-image">
                    <img src={arch} alt="" />
                </div>
                <div className="homegallery-footer-horizontal"></div>
                </div>
                </div>
            </div>
      </div>
     
         <div className='porfolio-container'>
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
         </div>
      
    </div>
  )
}

export default Portfolio