import React, { useEffect } from 'react';
import "./homegallery.css";
import arch from "../../asset/wedding.png";
import GalleryCard from '../gallerycard/GalleryCard';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const HomeGallery = () => {



    useEffect(() => {
        AOS.init();
    }, []);

    const navigateToPortfolio = () => {
        const newUrl = "/portfolio";
        window.location.href = newUrl;
    }


    return (
        <div className='homegallery'>
            <div className='homegallery-container'>
                <div className="homegallery-title-container" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1300">
                    <div className="homegallery-title">
                        <h1>Events</h1>
                    </div>
                    <div className="homegallery-sub-title">
                        <h3>VIEW OUR</h3>
                        <h1>Latest Events</h1>
                        <div className="homegallery-footer">
                            <div className="homegallery-footer-horizontal"></div>
                            <div className="homegallery-footer-image">
                                <img src={arch} alt="" />
                            </div>
                            <div className="homegallery-footer-horizontal"></div>
                        </div>
                    </div>
                </div>
                <div className="homegallery-gallery-container" >
                    <div data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="700">
                        <GalleryCard />
                    </div>
                    <div data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="900">
                        <GalleryCard />
                    </div>
                    <div data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1100">
                        <GalleryCard />
                    </div>
                    <div data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1300">
                        <GalleryCard />
                    </div>
                </div>
                <div className="homegallery-cta-container">
                    <div className="homegallery-cta" onClick={navigateToPortfolio}>
                        <p>VIEW PORTFOLIO</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
