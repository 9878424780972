import React, { useEffect } from 'react'

import "./servicescard.css"


import AOS from 'aos';
import 'aos/dist/aos.css';

const ServicesCard = ({img, title, content}) => {

  useEffect(()=>{
    AOS.init()
  }, [])

  return (
    <div className='servicescard' data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000" >
        <div className="servicecard-img">
            <img src={img} alt="" />
        </div>
        <div className="servicecard-content">
            <h1>{title}</h1>
            <p>{content}</p>
        </div>
    </div>
  )
}

export default ServicesCard