import React, {useState } from 'react'

import e1 from "../../asset/hb3.jpg"

import "./gallerycard.css"

import { motion} from "framer-motion"

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ls1 from "../../asset/h1.jpg"
import ls2 from "../../asset/h2.jpg"
import ls3 from "../../asset/h3.jpg"
import ls4 from "../../asset/hb1.jpg"
import ls5 from "../../asset/hb2.jpg"
import ls6 from "../../asset/hb3.jpg";

import Modals from '../Modals';

const cardData = [
  {
    image: [
      {
        largeUrl: ls1,
        thumbnailUrl: ls1,
        width: 1875,
        height: 2500,
      },
      {
        largeUrl: ls2,
        thumbnailUrl: ls2,
        width: 1875,
        height: 2500,
      },
      {
        largeUrl: ls3,
        thumbnailUrl: ls3,
        width: 1875,
        height: 2500,
      },
    ],
  },
];



const GalleryCard = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isHovered, setIsHovered] = useState(false); 



  return (
    <motion.div 
      className='gallerycard' 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
         <motion.div className={`gallerycard-image ${isHovered ? 'hovered' : ''}`} onClick={handleShow}>
            <motion.img src={e1} alt="" />
        </motion.div>
        <motion.div 
            className="gallery-details"  
            initial={{ opacity: 0, y: 120}}
            whileInView={{ opacity: 1, y: 0}}
            transition={{duration: 0.2}}
            >
            <div className="gallery-details-content">
            <h1>Lois & John</h1>
            <p >lorem gdjggdf hsghdgdhd hhxdvvdhv gdghudhd jdhgdfgdf.</p>
            <h3 >#L&S forever</h3>
            </div>
        </motion.div>

      
        <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color: "#7D3494", fontFamily: "Libre Caslon Display serif"}}>
          <div className="homegallery-footer">
                <div className="homegallery-footer-horizontal"></div>
                <h4 style={{margin: "0 1rem"}}>Lois & John</h4>
                <div className="homegallery-footer-horizontal"></div>
                </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="gallery-modal">
            <Modals images={cardData[0].image} galleryID="my-test-gallery" style={{objectFit:"cover"}}/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Lois & John</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="gallery-modal">
            <div className="gallery-modal-image">
              {
                cardData[0].image.map((item, index)=>{
                  return(
                    <div className='wrapper-image'>
                      <img src={item.img} alt="" onClick={()=> handleClick(item, index)}/>
                    </div>
                  )
                })
              }
              {
                clickedImage && <Modals clickedImg={clickedImage} /> 
              }
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

    </motion.div>
  )
}



export default GalleryCard