import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';

import "./addevent.css"

function AddEvent() {
  const { Formik } = formik;

  const schema = yup.object().shape({
    brideName: yup.string().required(),
    groomName: yup.string().required(),
    hashtag: yup.string().required(),
    description: yup.string().required(),
    mainImage: yup.mixed().required(),
    otherImages: yup.mixed().required(),
    terms: yup.bool().required().oneOf([true], 'terms must be accepted'),
  });

  return (
    <div className="addevent">
    <Formik
      validationSchema={schema}
      onSubmit={console.log}
      initialValues={{
        brideName: 'Mark',
        groomName: 'Otto',
        hashtag: '',
        description: '',
        mainImage: null,
        otherImages: null,
        terms: false,
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit} className='event-form'>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="4"
              controlId="validationFormik101"
              className="position-relative"
            >
              <Form.Label>Bride's name</Form.Label>
              <Form.Control
                type="text"
                name="brideName"
                value={values.brideName}
                onChange={handleChange}
                isValid={touched.brideName && !errors.brideName}
              />
              <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md="4"
              controlId="validationFormik102"
              className="position-relative"
            >
              <Form.Label>Groom's name</Form.Label>
              <Form.Control
                type="text"
                name="groomName"
                value={values.groomName}
                onChange={handleChange}
                isValid={touched.groomName && !errors.groomName}
              />

              <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormikUsername2">
              <Form.Label>Hashtag</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">#</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="hashtag"
                  aria-describedby="inputGroupPrepend"
                  name="hashtag"
                  value={values.hashtag}
                  onChange={handleChange}
                  isInvalid={!!errors.hashtag}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.hashtag}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="12"
              controlId="validationFormik103"
              className="position-relative"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter description"
                name="description"
                value={values.description}
                onChange={handleChange}
                isInvalid={!!errors.description}
              />

              <Form.Control.Feedback type="invalid" tooltip>
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Main image</Form.Label>
            <Form.Control
              type="file"
              required
              name="mainImage"
              onChange={handleChange}
              isInvalid={!!errors.mainImage}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.mainImage}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="position-relative mb-3">
            <Form.Label>Other images</Form.Label>
            <Form.Control
              type="file"
              
              name="otherImage"
              onChange={handleChange}
              isInvalid={errors.otherImages}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.otherImages}
            </Form.Control.Feedback>
          </Form.Group>


          <Form.Group className="position-relative mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={handleChange}
              isInvalid={!!errors.terms}
              feedback={errors.terms}
              feedbackType="invalid"
              id="validationFormik106"
              feedbackTooltip
            />
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      )}
    </Formik>
    </div>
  );
}

export default AddEvent;